'use strict';

import * as insertCss from 'insert-css';

// Class
// -----

export default class KenBurnsSlideshow {
  private props;
  private state;
  private css = `html {
    padding: 0;
    height: 100%;
  }
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .{{prefix}} {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .{{prefix}}__slide {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden;
  }
  .{{prefix}}__slide-img {
    height: 100%;
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-duration: 3s, {{transitionDelay}}ms;
  }
  .{{prefix}}--has-kenBurnsFx {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  `;

  constructor(props) {
    this.props = this.setProps(props);

    this.state = {
      // 'true' if the slideshow is runing.
      isRunning: false,
      // DOM node of currently visible slide.
      slideVisibleEl: null,
      // Number of currently visible slide.
      slideVisibleNum: null,
      // Holds the `setInterval` reference.
      interval: null
    };
  }

  // Public
  // ------

  public init() {
    this.injectCss(this.props.cssPrefix, this.props.transitionDelay);
    this.injectHtml(this.props.el, this.props.cssPrefix);
    this.randomizeEffectOrigin(this.props.el, this.props.cssPrefix);

    this.state.interval = this.startSlide(this.props, this.state);
    this.state.isRunning = true;
    this.state.slideVisibleEl = this.props.el.querySelector('.' + this.props.cssPrefix + '--has-kenBurnsFx');
    this.state.slideVisibleNum = 0;
  }

  public stop() {
    clearInterval(this.state.interval);
    this.state.isRunning = false;
  }

  public update() {
    this.stop();
    this.clearStylesLI(this.props.el, this.props.cssPrefix);

    console.log('props: ', this.props);
    console.log('state', this.state);
    this.injectHtml(this.props.el, this.props.cssPrefix);
    this.randomizeEffectOrigin(this.props.el, this.props.cssPrefix);


    this.state.slideVisibleNum = 0;
    this.state.interval = this.startSlide(this.props, this.state);
    this.state.isRunning = true;
    this.state.slideVisibleEl = this.props.el.querySelector('.' + this.props.cssPrefix + '--has-kenBurnsFx');
    this.state.slideVisibleNum = 0;
  }

  // Private
  // -------

  private setProps(props) {
    const props_: any = {};

    // Properties validation.
    if (!props.el) {
      throw new Error('Property `el` must be declared');
    }

    props_.el = props.el;
    props_.cssPrefix = props.cssPrefix || 'ken-burns-slideshow';
    props_.transitionDelay = props.transitionDelay || 5000;

    return props_;
  }

  private injectHtml(el, cssPrefix) {
    el.classList.add(cssPrefix);
    [].forEach.call(el.querySelectorAll('li'), li => {
      li.classList.add(cssPrefix + '__slide');
    });
    [].forEach.call(el.querySelectorAll('img'), (img, index) => {
      if (index === 0) {
        img.classList.add(cssPrefix + '--has-kenBurnsFx');
      }
      img.classList.add(cssPrefix + '__slide-img');
    });
  }

  private injectCss(cssPrefix, transitionDelay) {
    const css = this.css.replace(/{{prefix}}/g, cssPrefix).replace(/{{transitionDelay}}/g, transitionDelay);
    insertCss(css);
  }

  private randomizeEffectOrigin(el, cssPrefix) {
    const yAxisOrigins = ['bottom', 'center', 'top'];
    const xAxisOrigins = ['left', 'center', 'right'];
    const slides = el.querySelectorAll('.' + cssPrefix + '__slide-img');
    [].forEach.call(slides, slide => {
      const xAxisOrigin = this.getRandomElement(xAxisOrigins);
      const yAxisOrigin = this.getRandomElement(yAxisOrigins);

      if (slide.style.webkitTransformOrigin !== undefined) {
        slide.style.webkitTransformOrigin = '' + xAxisOrigin + ' ' + yAxisOrigin;
      } else {
        slide.style.transformOrigin = '' + xAxisOrigin + ' ' + yAxisOrigin;
      }
    });
  }

  private clearStylesLI(el, cssPrefix) {
    const slides = el.querySelectorAll('.' + cssPrefix + '__slide');
    const images = el.querySelectorAll('.' + cssPrefix + '__slide-img');
    slides.forEach(slide => {
      slide.style = '';
    });
    images.forEach(img => {
      img.style = '';
      img.classList.remove(cssPrefix + '--has-kenBurnsFx');
    });
  }

  private getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  private startSlide(props, state) {
    return setInterval(this.changeSlide.bind(null, props, state), props.transitionDelay);
  }

  private changeSlide(props, state) {
    const slides = props.el.querySelectorAll('.' + props.cssPrefix + '__slide-img');

    if (state.slideVisibleNum + 1 === slides.length) {
      state.slideVisibleNum = 0;
    } else {
      state.slideVisibleNum += 1;
    }

    state.slideVisibleEl.classList.remove(props.cssPrefix + '--has-kenBurnsFx');

    state.slideVisibleEl = slides[state.slideVisibleNum];

    state.slideVisibleEl.classList.add(props.cssPrefix + '--has-kenBurnsFx');
  }
}
