import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Pipe({
  name: 'downloadUrl'
})
export class DownloadurlPipe implements PipeTransform {

  constructor(public storage: AngularFireStorage){}

  transform(featuredPhoto: any): any {
      let photo = this.storage.ref(featuredPhoto).getDownloadURL();
      return photo;
  }

}
